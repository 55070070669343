import React from 'react';

function EditToolIcon(props) {
  return (
    <svg
      version="1.1"
      x="0px"
      y="0px"
      width="100%"
      height="100%"
      viewBox="0 0 50 50"
      enableBackground="new 0 0 50 50"
    >
      <g id="Layer_1" display="none">
        <g id="rEw2ek.tif" display="inline">
          <g>
            <path
              fill={props.fill}
              d="M15.538,41.333c-0.192,0.769-0.5,1.456-0.957,2.069c-1.04,1.396-2.424,2.209-4.159,2.396
          c-2.7,0.29-5.277-1.358-6.159-3.978c-1.016-3.016,0.604-6.342,3.611-7.385c0.152-0.054,0.212-0.131,0.24-0.281
          c0.518-2.84,1.041-5.678,1.561-8.519c0.053-0.287,0.102-0.575,0.156-0.874c-1.561-1.129-2.453-2.645-2.56-4.577
          c-0.083-1.5,0.376-2.842,1.326-4.009c2.038-2.506,5.895-2.934,8.433-0.68c3.937-1.812,7.878-3.627,11.822-5.441
          c0.021-1.527,0.524-2.858,1.549-3.98c0.867-0.95,1.939-1.549,3.201-1.798c2.518-0.496,5.088,0.724,6.289,2.982
          c0.676,1.268,0.868,2.611,0.566,4.019c-0.303,1.399-1.043,2.528-2.161,3.422c0.103,0.396,0.206,0.785,0.31,1.175
          c0.976,3.685,1.951,7.368,2.922,11.057c0.037,0.144,0.101,0.205,0.244,0.252c2.222,0.7,3.625,2.187,4.135,4.455
          c0.544,2.426-0.604,4.975-2.752,6.232c-1.203,0.706-2.502,0.961-3.879,0.749c-1.435-0.222-2.625-0.896-3.576-1.995
          c-0.037-0.044-0.076-0.087-0.131-0.148C28.896,38.094,22.221,39.712,15.538,41.333z M15.116,37.408
          c6.398-1.553,12.788-3.102,19.165-4.647c0.137-2.397,1.258-4.131,3.354-5.25c-1.023-3.869-2.046-7.734-3.066-11.601
          c-1.785-0.085-3.241-0.812-4.394-2.154c-3.762,1.731-7.506,3.455-11.249,5.178c0.238,1.696-0.125,3.225-1.167,4.566
          c-1.043,1.339-2.436,2.068-4.126,2.255c-0.537,2.925-1.072,5.837-1.608,8.761C13.41,35.104,14.436,36.062,15.116,37.408z
           M38.143,10.049c0.004-1.893-1.521-3.428-3.406-3.432c-1.889-0.004-3.43,1.525-3.438,3.411c-0.006,1.882,1.535,3.431,3.415,3.435
          C36.602,13.466,38.141,11.938,38.143,10.049z M43.584,32.818c0-1.889-1.535-3.422-3.421-3.42
          c-1.888,0.002-3.425,1.545-3.425,3.428c0.003,1.885,1.539,3.42,3.43,3.419C42.057,36.243,43.584,34.712,43.584,32.818z
           M9.833,43.387c1.891-0.005,3.417-1.535,3.417-3.427c-0.001-1.892-1.531-3.416-3.424-3.414c-1.888,0.001-3.423,1.537-3.421,3.425
          C6.407,41.854,7.951,43.393,9.833,43.387z M16.549,19.911c0.007-1.881-1.515-3.423-3.39-3.432
          c-1.905-0.009-3.442,1.512-3.449,3.411c-0.005,1.881,1.516,3.423,3.39,3.434C15,23.336,16.542,21.81,16.549,19.911z"
            />
          </g>
        </g>
      </g>
      <g id="Layer_2">
        <g>
          <line
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="37.445"
            y1="13.838"
            x2="40.558"
            y2="28.771"
          />
          <line
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="16.751"
            y1="17.172"
            x2="31.5"
            y2="10.731"
          />
          <line
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="8.894"
            y1="36.509"
            x2="10.979"
            y2="24.547"
          />
          <line
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            x1="13.135"
            y1="40.573"
            x2="36.356"
            y2="35.216"
          />
        </g>
        <path
          display="none"
          fill="none"
          stroke={props.fill}
          strokeWidth="3"
          strokeMiterlimit="10"
          d="M12.224,24.645l-1.891,10.744
      c0.477,0.076,3.7,3.227,3.801,3.677l21.349-4.879c-0.375-2.452,0.841-5.191,3.653-5.765l-2.843-13.511"
        />
        <path
          display="none"
          fill="none"
          stroke={props.fill}
          strokeWidth="3"
          strokeMiterlimit="10"
          d="M30.87,12.172l-13.307,5.756"
        />
        <g>
          <ellipse
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            cx="11.895"
            cy="19.292"
            rx="5.291"
            ry="5.34"
          />

          <ellipse
            id="XMLID_2_"
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            cx="36.356"
            cy="8.611"
            rx="5.291"
            ry="5.34"
          />
          <ellipse
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            cx="41.647"
            cy="33.996"
            rx="5.291"
            ry="5.34"
          />
          <ellipse
            fill="none"
            stroke={props.fill}
            strokeWidth="2"
            strokeMiterlimit="10"
            cx="7.978"
            cy="41.764"
            rx="5.291"
            ry="5.34"
          />
        </g>
      </g>
    </svg>
  );
}

export default EditToolIcon;
